// Instead of polyfills.ts
import 'zone.js';
import '@angular/localize/init';
// import 'global-polyfill';
// ============================== //

import { LOCALE_ID, NgModule, Injectable, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';

import localeHe from '@angular/common/locales/he';
import localeHeExtra from '@angular/common/locales/extra/he';

import localeAr from '@angular/common/locales/ar-IL';
import localeArExtra from '@angular/common/locales/extra/ar-IL';

import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';

registerLocaleData(localeHe, 'he', localeHeExtra);
registerLocaleData(localeAr, 'ar-IL', localeArExtra);
registerLocaleData(localeRu, 'ru', localeRuExtra);

import { CountryCode } from './intl-tel-input/data/country-code';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HammerModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { appStrings } from './_core/static/translations';
import { Observable } from 'rxjs';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': { enable: false },
        'rotate': { enable: false },
    }
}

import { MarkdownModule, MARKED_OPTIONS, MarkedRenderer } from 'ngx-markdown';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpInterceptorProviders } from './_core/refresh-token-interceptor';

import { CoreModule } from './_core/core.module';
import { SharedModule } from './_core/shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsLoaderService } from './_core/google-maps-loader.service';
import { AppService } from './app.service';
import { AuthService } from './_core/auth.service';
import { AuthGuardService } from './_core/auth-guard.service';
import { EntityService } from './_core/entity.service';
import { EntityUtilsService } from './_core/entity.utils.service';

import { TabitpayService } from './order/tabit-pay/tabit-pay.service';
import { TabitpayUtilsService } from './order/tabit-pay/tabit-pay.utils.service';
import { TabitpayExpayService } from './order/tabit-pay/tabit-pay.expay.service';

import { BookService } from './_core/book.service';
import { OccasionsService } from './_core/occasions.service';
import { MarketplaceService } from './_core/marketplace.service';
import { AppInitService } from './app-init.service';

import { DialogsService } from './_core/dialogs.service';
import { DatePipe, DecimalPipe } from '@angular/common'; // Built-In
import { NgxCollapseDirective, NgxDetectScrollDirective, IsAddressDirective } from './_core/directives';

import { DashboardComponent } from './dashboard/dashboard.component';

import { SignInComponent } from './sign-in/sign-in.component';
import { SignInByPhoneFormComponent } from './sign-in/sign-in-by-phone-form/sign-in-by-phone-form.component';
import { SignInByPhoneVerifyCodeFormComponent } from './sign-in/sign-in-by-phone-verify-code-form/sign-in-by-phone-verify-code-form.component';
import { SignInByEmailFormComponent } from './sign-in/sign-in-by-email-form/sign-in-by-email-form.component';
import { SignUpFormComponent } from './sign-in/sign-up-form/sign-up-form.component';
import { UpdateFormComponent } from './sign-in/update-form/update-form.component';

import { MyAccountComponent } from './profile/my-account/my-account.component';
import { MyPreferencesComponent } from './profile/my-preferences/my-preferences.component';
import { MyHistoryComponent } from './profile/my-history/my-history.component';
import { MyRankComponent } from './profile/my-rank/my-rank.component';

import { BillDialogComponent } from './dialogs/bill-dialog/bill-dialog.component';
import { BottomSheetDialogComponent } from './dialogs/bottom-sheet-dialog/bottom-sheet-dialog.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { GalleryDialogComponent } from './dialogs/gallery-dialog/gallery-dialog.component';
import { PreferencesSelectionDialogComponent } from './dialogs/preferences-selection-dialog/preferences-selection-dialog.component';
import { MenuSelectionDialogComponent } from './dialogs/menu-selection-dialog/menu-selection-dialog.component';
import { MyWalletComponent } from './profile/my-wallet/my-wallet.component';
import { MyAddressesComponent } from './profile/my-addresses/my-addresses.component';
import { FoodCategoriesComponent } from './food-categories/food-categories.component';
import { NewRestaurantsComponent } from './new-restaurants/new-restaurants.component';
import { ExternalDialogComponent } from './dialogs/external-dialog/external-dialog.component';
import { MyProfileComponent } from './profile/my-profile/my-profile.component';
import { NearMeComponent } from './near-me/near-me.component';
import { PreorderDialogComponent } from './dialogs/preorder-dialog/preorder-dialog.component';
import { PaymentDialogComponent } from './dialogs/payment-dialog/payment-dialog.component';

import { TabitPayComponent } from './order/tabit-pay/tabit-pay.component';
import { TabitpayEnterOrderComponent } from './order/tabit-pay/tabitpay-enter-order/tabitpay-enter-order.component';
import { TabitpayTicketComponent } from './order/tabit-pay/tabitpay-ticket/tabitpay-ticket.component';
import { TabitpayTicketSeatedComponent } from './order/tabit-pay/tabitpay-ticket-seated/tabitpay-ticket-seated.component';
import { QRScannerComponent } from './order/qr-scanner/qr-scanner.component';
import { QuickPayComponent } from './order/quick-pay/quick-pay.component';
import { SitesPayComponent } from './order/sites-pay/sites-pay.component';
import { TabitpayCheckoutFullComponent } from './order/tabit-pay/tabitpay-checkout-full/tabitpay-checkout-full.component';
import { PassOrderDialogComponent } from './order/tabit-pay/dialogs/pass-order-dialog/pass-order-dialog.component';
import { TabitpayPaymentDialogComponent } from './order/tabit-pay/dialogs/tabitpay-payment-dialog/tabitpay-payment-dialog.component';

import { TabitBookComponent } from './tabit-book/tabit-book.component';
import { TabitbookStartComponent } from './tabit-book/tabitbook-start/tabitbook-start.component';
import { TabitbookDetailsComponent } from './tabit-book/tabitbook-details/tabitbook-details.component';
import { TabitbookEndComponent } from './tabit-book/tabitbook-end/tabitbook-end.component';
import { TabitbookLicenseDialogComponent } from './tabit-book/tabitbook-license-dialog/tabitbook-license-dialog.component';
import { TabitbookAltComponent } from './tabit-book/tabitbook-alt/tabitbook-alt.component';

import { AnimationDialogComponent } from './dialogs/animation-dialog/animation-dialog.component';
import { FavoriteFabComponent } from './components/favorite-fab/favorite-fab.component';

import { KeypadDialogComponent } from './dialogs/keypad-dialog/keypad-dialog.component';
import { NonemptyValidator, CreditCardValidator, CreditCardExpirationValidator, IdCardValidator } from './components/validators';
import { TabitbookSelectionBarComponent } from './tabit-book/tabitbook-selection-bar/tabitbook-selection-bar.component';
import { SiteItemComponent } from './components/site-item/site-item.component';

import { FoodCategoryItemComponent } from './components/food-category-item/food-category-item.component';
import { FutureReservationsComponent } from './components/future-reservations/future-reservations.component';
import { SiteCardComponent } from './components/site-card/site-card.component';
import { PromotionCardComponent } from './components/promotion-card/promotion-card.component';
import { TabitbookFromSitesComponent } from './tabit-book/tabitbook-from-sites/tabitbook-from-sites.component';
import { TabitbookChooseSiteComponent } from './tabit-book/tabitbook-from-sites/tabitbook-choose-site/tabitbook-choose-site.component';
import { RatingComponent } from './components/rating/rating.component';
import { DashboardSectionComponent } from './dashboard/dashboard-section/dashboard-section.component';
import { LocationService } from './_core/location.service';
import { OrganizationsService } from './_core/organizations.service';
import { UserReviewsService } from './_core/user.reviews.service';
import { TagsService } from './_core/tags.service';
import { TimeSlotsComponent } from './components/time-slots/time-slots.component';
import { SmsDialogComponent } from './dialogs/sms-dialog/sms-dialog.component';

import { AddressFormComponent } from './profile/my-addresses/address-form/address-form.component';
import { WebContainerComponent } from './web-container/web-container.component';
import { WebHeaderComponent } from './web-container/web-header/web-header.component';
import { WebHeaderTabsComponent } from './web-container/web-header-tabs/web-header-tabs.component';
import { WebFooterComponent } from './web-container/web-footer/web-footer.component';
import { WebHomeComponent } from './web-container/web-home/web-home.component';
import { WebBookingResultsComponent } from './web-container/web-booking-results/web-booking-results.component';
import { WebBookHeaderComponent } from './web-container/web-home/web-book-header/web-book-header.component';
import { WebDeliveryComponent } from './web-container/web-delivery/web-delivery.component';
import { WebDeliveryHeaderComponent } from './web-container/web-delivery/web-delivery-header/web-delivery-header.component';
import { WebSitesComponent } from './web-container/web-sites/web-sites.component';
import { WebSitesHeaderComponent } from './web-container/web-sites/web-sites-header/web-sites-header.component';
import { SiteListMapComponent } from './components/site-list-map/site-list-map.component';
import { webArticlePreviewComponent } from './web-container/web-article-preview/web-article-preview.component';
import { WebTilesContainerComponent } from './web-container/web-tiles-container/web-tiles-container.component';
import { WebHeaderSearchComponent } from './web-container/web-header/web-header-search/web-header-search.component';
import { WebSelectionBarComponent } from './web-container/web-selection-bar/web-selection-bar.component';
import { WebSeoHeaderComponent } from './web-container/web-seo-header/web-seo-header.component';
import { WebMarketplaceComponent } from './web-container/web-marketplace/web-marketplace.component';

import { SitesSearchToolsComponent } from './components/sites-search-tools/sites-search-tools.component';
import { SitesOrderTaComponent } from './sites-order-ta/sites-order-ta.component';
import { SitesSearchComponent } from './sites-search/sites-search.component';
import { SitesSearchPayComponent } from './sites-search-pay/sites-search-pay.component';

// The old:
import { AppSiteDetailsComponent } from './app-site-detail/app-site-details.component';

import { WebSiteDetailsComponent } from './web-site-details/web-site-details.component';
import { SiteTitleDescComponent } from './web-site-details/site-title-desc/site-title-desc.component';
import { SiteActionsComponent } from './components/site-actions/site-actions.component';
import { SiteLinksComponent } from './web-site-details/site-links/site-links.component';
import { SiteInfoTabsComponent } from './web-site-details/site-info-tabs/site-info-tabs.component';
import { WebArticleComponent } from './web-container/web-article/web-article.component';
import { WebTilesNearMeComponent } from './web-container/web-tiles-near-me/web-tiles-near-me.component';
import { WebTilesNewRestaurantsComponent } from './web-container/web-tiles-new-restaurants/web-tiles-new-restaurants.component';
import { AddressDialogComponent } from './dialogs/address-dialog/address-dialog.component';
import { LocationWidgetComponent } from './components/location-widget/location-widget.component';
import { WebAppSmartBannerComponent } from './web-container/web-app-smart-banner/web-app-smart-banner.component';
import { StarRatingComponent } from './components/star-rating/star-rating/star-rating.component';
import { WebArticle } from './web-container/web-article/web-article';
import { UserReviewsComponent } from './user-reviews/user-reviews.component';
import { WebUserReviewComponent } from './user-reviews/web-user-review/web-user-review.component';
import { AppUserReviewComponent } from './user-reviews/app-user-review/app-user-review.component';
import { HistoryItemComponent } from './components/history-item/history-item.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { OrderTrackerComponent } from './components/order-tracker/order-tracker.component';
import { AppOrderTrackerComponent } from './components/order-tracker/app-order-tracker/app-order-tracker.component';
import { WebOrderTrackerComponent } from './components/order-tracker/web-order-tracker/web-order-tracker.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { EventsListComponent } from './events/events-list/events-list.component';
import { AppEventsComponent } from './events/app-events/app-events.component';
import { WebEventsComponent } from './web-container/web-events/web-events.component';
import { NotificationsWidgetComponent } from './notifications/notifications-widget/notifications-widget.component';
import { NotificationComponent } from './notifications/notification/notification.component';
import { SiteMetaDataComponent } from './components/site-meta-data/site-meta-data.component';
import { TrackedOrderItemComponent } from './components/tracked-order-item/tracked-order-item.component';
import { OrderTrackerHeaderComponent } from './components/order-tracker/order-tracker-header/order-tracker-header.component';
import { MyBenefitsComponent } from './profile/my-benefits/my-benefits.component';
import { OrderTrackerFooterComponent } from './components/order-tracker/web-order-tracker/order-tracker-footer/order-tracker-footer.component';
import { ClubCardComponent } from './loyalty/club-card/club-card.component';
import { BenefitCardComponent } from './loyalty/benefit-card/benefit-card.component';
import { BenefitDetailsComponent } from './loyalty/benefit-details/benefit-details.component';
import { WidgetOpenerComponent } from './notifications/widget-opener/widget-opener.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { AccessibilityMenuComponent } from './components/accessibility-menu/accessibility-menu.component';
import { OrderSummaryComponent } from './components/order-tracker/order-summary/order-summary.component';
import { HeaderTrackerComponent } from './components/order-tracker/header-tracker/header-tracker.component';
import { SignInDialogComponent } from './sign-in/sign-in-dialog/sign-in-dialog.component';
import { SplashScreenDialogComponent } from './dialogs/splash-screen-dialog/splash-screen-dialog.component';
import { ConsentTextComponent } from './components/consent-text/consent-text.component';
import { SpecialMessagesComponent } from './components/special-messages/special-messages.component';
import { MainMessageDialogComponent } from './dialogs/main-message-dialog/main-message-dialog.component';
import { AccessibilityDialogComponent } from './dialogs/accessibility-dialog/accessibility-dialog.component';
import { TabitpayExpayDialogComponent } from './order/tabit-pay/dialogs/tabitpay-expay-dialog/tabitpay-expay-dialog.component';
import { FooterLinksComponent } from './tabit-order/components/footer-links/footer-links.component';
import { FooterLinksButtonComponent } from './tabit-order/components/footer-links/footer-links-button/footer-links-button.component';
import { ShortCodeComponent } from './loyalty/short-code/short-code.component';
import { MultipleChoiceComponent } from './dialogs/multiple-choice/multiple-choice.component';
import { LoyaltyTermsDialogComponent } from './dialogs/loyalty-terms-dialog/loyalty-terms-dialog.component';
import { RemoveAccountComponent } from './profile/remove-account/remove-account.component';
import { VerifyCodeDialogComponent } from './sign-in/verify-code-dialog/verify-code-dialog.component';
import { AccessabilitySupportModule } from './accessability/accessability-support.module';
import { OutOfUseComponent } from './out-of-use/out-of-use.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MarketplaceCardComponent } from './components/marketplace-card/marketplace-card.component';
import { SelectedTagComponent } from './marketplace/selected-tag/selected-tag.component';
import { AppMarketplaceComponent } from './marketplace/app-marketplace/app-marketplace.component';
import { TabitpayOfferSelectComponent } from './order/tabit-pay/dialogs/tabitpay-offer-select/tabitpay-offer-select.component';
import { AuthorizenetPayDialogComponent } from './dialogs/authorizenet-pay-dialog/authorizenet-pay-dialog.component';
import { MarketplaceImageComponent } from './components/marketplace-card/marketplace-image/marketplace-image.component';
import { SiteMoreInfoDialogComponent } from './dialogs/site-more-info-dialog/site-more-info-dialog.component';
import { TestScannerComponent } from './order/test-scanner/test-scanner.component';
import { GenericItemImageComponent } from './components/generic-item-image/generic-item-image.component';

// Disabling ripples animation globally
const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
    animation: {
        enterDuration: 0,
        exitDuration: 0
    }
};

export class TranslationLoader implements TranslateLoader {
    getTranslation(lang: string) {
        // Condition satisfies upon page load. com.json is loaded.
        return new Observable(observer => {
            if (appStrings[lang]) {
                observer.next(appStrings[lang]);
                observer.complete();
            } else {
                observer.error('No app strings provided');
            }
        });
    };
}

// Markdown links are opened in a new window
export function markedOptionsFactory(): any {
    const renderer = new MarkedRenderer();

    renderer.link = (href: string, title?: string, text?: string) => {
        return `<a href="${href}" ${(title ? `title="${title}"` : '')} target="${window['cordova'] ? '_system' : '_blank'}">${text}</a>`;
    };
    return { renderer };
}

export function loadAfterBundle(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.Init();
    }
}

@NgModule({
    declarations: [
        NgxCollapseDirective,
        NgxDetectScrollDirective,
        IsAddressDirective,
        AppComponent,
        AppSiteDetailsComponent,
        DashboardComponent,
        SignInComponent,
        SignUpFormComponent,
        UpdateFormComponent,
        SignInByPhoneFormComponent,
        SignInByPhoneVerifyCodeFormComponent,
        SignInByEmailFormComponent,
        MyAccountComponent,
        MyPreferencesComponent,
        MyHistoryComponent,
        QuickPayComponent,
        BillDialogComponent,
        BottomSheetDialogComponent,
        MessageDialogComponent,
        MyRankComponent,
        GalleryDialogComponent,
        PreferencesSelectionDialogComponent,
        MenuSelectionDialogComponent,
        MyWalletComponent,
        MyAddressesComponent,
        AddressFormComponent,
        FoodCategoriesComponent,
        NewRestaurantsComponent,
        ExternalDialogComponent,
        MyProfileComponent,
        NearMeComponent,
        TabitPayComponent,
        TabitpayEnterOrderComponent,
        TabitpayTicketComponent,
        TabitpayTicketSeatedComponent,
        QRScannerComponent,
        TabitBookComponent,
        TabitbookStartComponent,
        TabitbookDetailsComponent,
        TabitbookEndComponent,
        TabitbookLicenseDialogComponent,
        TabitbookAltComponent,
        SitesPayComponent,
        AnimationDialogComponent,
        FavoriteFabComponent,
        TabitpayCheckoutFullComponent,
        KeypadDialogComponent,
        NonemptyValidator,
        CreditCardValidator,
        CreditCardExpirationValidator,
        IdCardValidator,
        TabitbookSelectionBarComponent,
        SiteItemComponent,
        PassOrderDialogComponent,
        TabitpayPaymentDialogComponent,
        FoodCategoryItemComponent,
        FutureReservationsComponent,
        SiteCardComponent,
        PromotionCardComponent,
        TabitbookFromSitesComponent,
        TabitbookChooseSiteComponent,
        RatingComponent,
        DashboardSectionComponent,
        TimeSlotsComponent,
        SmsDialogComponent,
        WebContainerComponent,
        WebHeaderComponent,
        WebHeaderTabsComponent,
        WebFooterComponent,
        WebHomeComponent,
        WebBookHeaderComponent,
        WebDeliveryComponent,
        WebDeliveryHeaderComponent,
        WebSitesComponent,
        WebSitesHeaderComponent,
        SiteListMapComponent,
        webArticlePreviewComponent,
        WebTilesContainerComponent,
        WebHeaderSearchComponent,
        WebSelectionBarComponent,
        WebSeoHeaderComponent,
        SitesOrderTaComponent,
        SitesSearchToolsComponent,
        SitesSearchComponent,
        WebSiteDetailsComponent,
        SiteTitleDescComponent,
        SiteActionsComponent,
        SiteLinksComponent,
        SiteInfoTabsComponent,
        WebBookingResultsComponent,
        WebArticleComponent,
        WebTilesNearMeComponent,
        WebTilesNewRestaurantsComponent,
        AddressDialogComponent,
        PreorderDialogComponent,
        PaymentDialogComponent,
        LocationWidgetComponent,
        SitesSearchPayComponent,
        WebAppSmartBannerComponent,
        StarRatingComponent,
        UserReviewsComponent,
        AppUserReviewComponent,
        WebUserReviewComponent,
        HistoryItemComponent,
        MenuItemComponent,
        OrderTrackerComponent,
        AppOrderTrackerComponent,
        WebOrderTrackerComponent,
        EventCardComponent,
        EventsListComponent,
        AppEventsComponent,
        WebEventsComponent,
        NotificationsWidgetComponent,
        NotificationComponent,
        SiteMetaDataComponent,
        TrackedOrderItemComponent,
        OrderTrackerHeaderComponent,
        MyBenefitsComponent,
        OrderTrackerFooterComponent,
        ClubCardComponent,
        BenefitCardComponent,
        BenefitDetailsComponent,
        WidgetOpenerComponent,
        UnderConstructionComponent,
        AccessibilityMenuComponent,
        OrderSummaryComponent,
        HeaderTrackerComponent,
        SignInDialogComponent,
        SplashScreenDialogComponent,
        ConsentTextComponent,
        AccessibilityDialogComponent,
        SpecialMessagesComponent,
        MainMessageDialogComponent,
        TabitpayExpayDialogComponent,
        FooterLinksComponent,
        FooterLinksButtonComponent,
        ShortCodeComponent,
        MultipleChoiceComponent,
        LoyaltyTermsDialogComponent,
        RemoveAccountComponent,
        VerifyCodeDialogComponent,
        OutOfUseComponent,
        TabitpayOfferSelectComponent,
        MarketplaceComponent,
        MarketplaceCardComponent,
        WebMarketplaceComponent,
        SelectedTagComponent,
        AppMarketplaceComponent,
        AuthorizenetPayDialogComponent,
        MarketplaceImageComponent,
        SiteMoreInfoDialogComponent,
        TestScannerComponent,
        GenericItemImageComponent,
    ],
    imports: [
        AccessabilitySupportModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.appConfig.firebaseConfig),

        AngularFireMessagingModule,

        HammerModule,
        GoogleMapsModule,
        FormsModule,
        ReactiveFormsModule,
        MatMomentDateModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslationLoader,
                deps: [HttpClient]
            }
        }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptionsFactory,
            },
        }),
    ],
    providers: [
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        { provide: LOCALE_ID, useValue: environment.appConfig['locale'] },
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
        { provide: APP_INITIALIZER, useFactory: loadAfterBundle, deps: [AppInitService], multi: true },
        AppService,
        AuthService,
        AuthGuardService,
        EntityService,
        EntityUtilsService,
        TabitpayService,
        BookService,
        OccasionsService,
        MarketplaceService,
        TabitpayUtilsService,
        TabitpayExpayService,
        DialogsService,
        LocationService,
        OrganizationsService,
        UserReviewsService,
        TagsService,
        HttpInterceptorProviders,
        DatePipe,
        DecimalPipe,
        WebArticle,
        GoogleMapsLoaderService,
        CountryCode,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
