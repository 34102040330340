import { Component, NgZone, OnInit } from '@angular/core';

import { AppService } from '../../app.service';
import { AccountService } from '../../_core/account.service';
import { AuthService } from '../../_core/auth.service';
import { DialogsService } from '../../_core/dialogs.service';
import { EntityService } from '../../_core/entity.service';

@Component({
    selector: 'remove-account',
    templateUrl: './remove-account.component.html',
    styleUrls: ['./remove-account.component.scss']
})
export class RemoveAccountComponent implements OnInit {

    constructor(
        public appService: AppService,
        public dialogsService: DialogsService,
        public accountService: AccountService,
        public authService: AuthService,
        public entityService: EntityService,
        public ngZone: NgZone,
    ) { }

    ngOnInit(): void { }

    deleteCustomer() {
        const phone = this.appService.user.phone || this.appService?.user?.loyaltyCustomer?.Mobile;
        if (!phone) return;

        return this.appService.mainMessage({
            dialogType: 'info',
            dialogText: "delete_account",
            primaryButtonText: "accept",
            secondaryButtonText:"Cancel",
            hideSecondaryButton: false,
        }).then(res => {
            const args = { phone };

            this.appService.startBlock();
            // 20-06-2022 - Consider moving the deletion of the user to somewhere more global
            this.authService.authMobile$(args)
            .subscribe(response => {
                if (response.IsSuccess && response.ResponseData.LoginResult == 'UserExistInLoyalty') {
                    this.appService.stopBlock();
                    this.dialogsService.showVerifyCodeDialog(args)
                    .then(res => {
                        this.appService.startBlock();
                        this.authService.deleteLoyaltyUser$()
                        .subscribe(res => {
                            console.debug('Deleted user from Loyalty services', res);
                            this.authService.deleteUserFromBridge$()
                            .subscribe(res => {
                                setTimeout(() => {
                                    this.appService.stopBlock();
                                }, 200);
                                console.debug('Deleted user\'s meta data');
                                this.appService.signOut(false, true);
                            })
                        })
                    })
                } else {
                    this.appService.stopBlock();
                    return this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: "MESSAGES.SERVER_ERROR",
                        hideSecondaryButton: true,
                    })
                }
            });
        }).catch(err => {
            this.appService.stopBlock();
        });
    }

}
